'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form } from 'antd'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { dateFormat, datetimeFormat } from '@/branding-config'

import StepHeader from '../StepHeader'
import ImportBody from '../../Modals/FileHub/ImportBody'

interface UploadDocumentStepProps {
  companyDetails?: { [x: string]: string }
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  goBack: () => void
}

const UploadDocumentStep: React.FC<UploadDocumentStepProps> = ({
  companyDetails,
  getAnswerWrapper,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep } = useGrantApplicationState()
  const { selectedConversation } = useDrawerState()

  const finish = async () => {
    setCurrentStep(currentStep + 1)
    const newContext = `Company URL: ${companyDetails?.companyUrl}
      ${companyDetails?.address ? `Address: ${companyDetails.address}` : ''}
      ${companyDetails?.numberOfEmployees ? `Number of employees: ${companyDetails.numberOfEmployees}` : ''}
      ${companyDetails?.annualRevenue ? `Annual revenue: ${companyDetails.annualRevenue}` : ''}
      ${companyDetails?.additionalInfo ? `Additional company information: ${companyDetails.additionalInfo}` : ''}.`
    await getAnswerWrapper(
      `${newContext}
      
      Research my company with the search and scrape function and context and list in detail the information you learned about my company.
      Present me with a very long and very detailed description of my company, including the company's address, number of employees, annual revenue, and everything else about the company that you can find.
      Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
      Include precise and detailed financial information about my company.
      FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
      FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}`,
      true
    )
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title='Add docs to your knowledge base'
        description='Add documents here to give the AI more information to match you with grants, and draft the proposal.'
        tooltip='These documents will be stored in your dashboard. You can add/edit your knowledge base as needed. This is your repository of information relevant to your proposal.'
      />
      <ImportBody conversationId={selectedConversation} />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col text-left'
        requiredMark='optional'
        layout='vertical'
      >
        <div className='m-0 flex justify-between gap-2'>
          <Button onClick={goBack}>Back</Button>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              icon={<RiSparklingFill className='size-5' />}
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default UploadDocumentStep
