'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import StepHeader from '../StepHeader'

import { IQuestion } from '@/types/chatbot'

interface PreferredGrantsStepProps {
  setPreferredGrants: (preferredGrants?: string) => void
  gatherGrants: (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => Promise<void>
  goBack: () => void
}

const PreferredGrantsStep: React.FC<PreferredGrantsStepProps> = ({
  setPreferredGrants: setPreferredGrants,
  gatherGrants,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { currentStep, setCurrentStep, steps, setSteps } =
    useGrantApplicationState()

  const finish = async (values: { [x: string]: string }) => {
    setPreferredGrants(values.preferredGrants)
    setSteps({
      ...steps,
      [currentStep]: values,
    })
    setCurrentStep(currentStep + 1)
    await gatherGrants()
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title='Preferred Grants'
        description='If you are already aware of relevant grants you can list them here.'
        tooltip='Tell us more about the types of grants you are interested in or any specific instructions to help us find the best matches for you.'
      />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col'
        layout='vertical'
        requiredMark='optional'
        initialValues={steps[currentStep]}
      >
        <Form.Item name='preferredGrants' rules={[{ type: 'string' }]}>
          <Input.TextArea placeholder='SBIR/STTR' rows={5} />
        </Form.Item>

        <div className='m-0 flex justify-between gap-2'>
          <Button onClick={goBack}>Back</Button>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              icon={<RiSparklingFill className='size-5' />}
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default PreferredGrantsStep
