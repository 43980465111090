'use client'

import { Button, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { SetStateAction } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useConversation from '@/hooks/useConversation'

import DOC from '@/assets/icons/doc'
import PDF from '@/assets/icons/pdf'

import { configFileHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import StepHeader from '../StepHeader'

import { Section } from '@/types/document'

interface UploadGrantStepProps {
  mutateConversations?: () => void
  setSections: (value: SetStateAction<Section[] | undefined>) => void
  setLoading: (loading: boolean) => void
  goBack: () => void
}

const UploadGrantStep: React.FC<UploadGrantStepProps> = ({
  mutateConversations,
  setSections,
  setLoading,
  goBack,
}) => {
  const { setCurrentStep, currentStep, steps, setSteps } =
    useGrantApplicationState()
  const { selectedConversation } = useDrawerState()
  const { selectedAgent } = useAgents()
  const { mutate: mutateConversation } = useConversation(selectedConversation)

  const onChange = async (info: {
    file: {
      name?: string
      status?: string
      originFileObj?: Blob
      filename?: string
    }
  }) => {
    const { status, originFileObj, filename } = info.file
    if (status === 'done') {
      setLoading(true)
      setCurrentStep(currentStep + 1)

      try {
        if (originFileObj) {
          const formData = new FormData()
          formData.append('file', originFileObj, filename)
          formData.append('conversationId', selectedConversation as string)
          formData.append('agentId', selectedAgent.id as string)

          const res = await axios({
            url: `${API_URL}/google-doc/extract-text`,
            method: 'post',
            withCredentials: true,
            data: formData,
            ...configFileHeader,
          })
          mutateConversation()
          mutateConversations?.()
          const text = res.data.sections

          let json: { [key: string]: Section }
          if (typeof text === 'string') {
            json = JSON.parse(
              text.replaceAll('```json', '').replaceAll('```', '')
            )
          } else {
            json = text
          }
          const newSections = Object.keys(json).map((key: string) => ({
            id: `${Math.random().toString(36)}-${Date.now()}`, // generate random id for section
            title: json[key]?.title ?? '',
            text: json[key]?.text ?? '',
            subsections:
              json[key]?.subsections.map(
                (subsection: { title: string; text: string }) => ({
                  id: `sub-${Math.random().toString(36)}-${Date.now()}`, // generate random id for subsection
                  title: subsection.title,
                  text: subsection.text,
                })
              ) ?? [],
          }))
          setSections(newSections)

          setSteps({
            ...steps,
            [currentStep + 1]: {
              ...steps[currentStep + 1],
              numQuestions: 1,
              sections: newSections,
            },
          })
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        message.error('Failed to extract text from the uploaded file')
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-4 rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader title='Upload grant application draft' />
      <Dragger
        onChange={onChange}
        maxCount={1}
        accept='.docx,.pdf'
        showUploadList={false}
      >
        <div className='flex w-full flex-col items-center justify-center gap-2 p-5'>
          <div className='flex gap-2'>
            <PDF className='size-10 md:size-16' />
            <DOC className='size-10 md:size-16' />
          </div>
          <p className='text-base'>
            Upload grant application you want to refine
          </p>
          <p className='text-xs'>Click or drag a file to this area to upload</p>
        </div>
      </Dragger>
      <Button onClick={goBack} className='w-fit'>
        Back
      </Button>
    </div>
  )
}

export default UploadGrantStep
