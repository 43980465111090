'use client'

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import {
  RiAddLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiDraggable,
  RiRefreshLine,
  RiSparklingFill,
} from '@remixicon/react'
import { Button, FloatButton, Input, message } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { SetStateAction, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'

import { dateFormat, datetimeFormat } from '@/branding-config'
import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { getAnswerDraft, mapConversation } from '@/service/Chatbot'
import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'

import ActionButtons from './ActionButtons'
import CollapseButton from './CollapseButton'
import StepHeader from '../StepHeader'
import lottieGeneratingDocument from '../../../../../public/lottieGeneratingDocument.json'
import lottieScanDocument from '../../../../../public/lottieScanDocument.json'

import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'
import { GrantApplicationMode } from '@/types/grants'

interface GrantEditorProps {
  sections?: Section[]
  setSections: (value: SetStateAction<Section[] | undefined>) => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack?: () => void
}

const GrantEditor: React.FC<GrantEditorProps> = ({
  sections,
  setSections,
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const { user } = useAuth()
  const {
    setCurrentStep,
    currentStep,
    steps,
    questions,
    setSteps,
    setQuestions,
    mode,
  } = useGrantApplicationState()
  const { selectedConversation } = useDrawerState()
  const { conversation } = useConversation(selectedConversation)
  const { agents, selectedAgent } = useAgents()
  const [editing, setEditing] = useState<{ [key: string]: boolean }>()
  const [enhancing, setEnhancing] = useState<{ [key: string]: boolean }>()
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>()
  const [textTitle, setTextTitle] = useState<{ [key: string]: string }>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [generatingDocument, setGeneratingDocument] = useState<boolean>(false)
  const [iframeKey, setIframeKey] = useState(false)
  const [initialSectionsStatus, setInitialSectionsStatus] = useState<
    'enhancing' | 'enhanced' | 'enhancingTwice' | 'enhancedTwice'
  >()

  useEffect(() => {
    if (
      conversation &&
      questions.length === 0 &&
      mode === GrantApplicationMode.CONTINUE_EXISTING
    ) {
      setQuestions(mapConversation(conversation, agents))
    }
  }, [conversation])

  useEffect(() => {
    setDocumentUrl(steps[currentStep]?.documentUrl)
  }, [steps[currentStep]?.documentUrl])

  useEffect(() => {
    if (sections) {
      return
    }

    if (!loading) {
      const text = questions[questions.length - 1]?.messages[1]?.message
      if (text && text !== '') {
        try {
          let json: { [key: string]: Section }
          if (typeof text === 'string') {
            json = JSON.parse(
              text.replaceAll('```json', '').replaceAll('```', '')
            )
          } else {
            json = text
          }
          const newSections = Object.keys(json).map((key: string) => ({
            id: `${Math.random().toString(36)}-${Date.now()}`, // generate random id for section
            title: json[key]?.title ?? '',
            text: json[key]?.text ?? '',
            subsections:
              json[key]?.subsections.map(
                (subsection: { title: string; text: string }) => ({
                  id: `sub-${Math.random().toString(36)}-${Date.now()}`, // generate random id for subsection
                  title: subsection.title,
                  text: subsection.text,
                })
              ) ?? [],
          }))

          setSteps({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              numQuestions: 1,
              sections: newSections,
            },
          })
          setSections(newSections)
        } catch (error) {
          console.error(error)
          message.error('Failed to parse the document')
          setCurrentStep(currentStep - 1)
        }
      }
    }
  }, [loading, questions[questions.length - 1]?.messages[1]?.message])

  const draftAnswer = (question: string, questions: IQuestion[]) => {
    return getAnswerDraft(
      true,
      question,
      1,
      questions,
      selectedAgent.id,
      undefined,
      true,
      false,
      user?.email,
      selectedConversation
    )
  }

  const createTmpMessage = (question: string): IQuestion => {
    return {
      question: question,
      messages: [
        {
          role: 'user',
          type: 'text',
          domain: true,
          message: question,
          agent: selectedAgent.id,
          drafts: [],
          documents: [],
          titles: [],
          googleDriveUrls: [],
          timestamp: dayjs().format(datetimeFormat),
          isCommand: false,
          feedbackScore: 0,
        },
      ],
    }
  }

  const enhance = async (section: Section, enhanceInstructions?: string) => {
    setEnhancing((prev) => ({ ...prev, [section.id]: true }))
    const question = `
      CONTEXT:

      ${sections?.map((s) => JSON.stringify(s)).join('\n')}


      TASK:
      Your goal is to enhance the following grant section by expanding on its content, making it significantly more detailed and thorough. The resulting text should be more elaborate and descriptive. Return only the generated text in markdown format:

      ${JSON.stringify(section)}


      ENHANCEMENT INSTRUCTIONS:

      - Ensure that the enhanced text is **at least twice as long** as the original. 2 times more words than the original text.
      - Expand on every idea, providing additional context, explanation, and examples where relevant.
      - If the text exceeds 500 words break up the section into **subsections** to improve readability and structure.
      - Use the same tone and style as the original text. Use the same structure and formatting. Try to mimic the original author's style.
      - Follow these specific guidelines for enhancement: ${enhanceInstructions}
      - Add more very detailed and specific information to the section.
      - Add more numbers and specific timelines and budget numbers.
      - If enhancing titles, ensure they are clear, concise, and descriptive. Do not add "enhanced" or "improved" to the title.
      - Always return section text in valid markdown format. Instead of \\n use an actual new line.
      - Always return subsection text in valid markdown format. Instead of \\n use an actual new line.


      RESPONSE FORMAT:
      Return the enhanced text in valid JSON with the following structure:
      {
        "title": "Section Title",
        "text": "Expanded section text in markdown",
        "subsections": [
          {
            "title": "Subsection Title",
            "text": "Expanded subsection text in markdown"
          }
        ]
      }


      ADDITIONAL RULES:
      - Subsections are optional, but create them when it makes sense based on titles and subtitles.
      - Do not use parentheses!
      - Do not use backslashes!
      - FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
      - FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
      `
    const tmpQuestion: IQuestion = createTmpMessage(question)
    const res: { answer: string } = await draftAnswer(question, [
      ...(questions ?? []),
      tmpQuestion,
    ])
    try {
      const json = JSON.parse(
        res.answer.replaceAll('```json', '').replaceAll('```', '')
      )
      setSections((prevSections) => {
        const newSections = prevSections?.map((s) => {
          if (s.id === section.id) {
            return {
              id: s.id,
              title: json.title,
              text: json.text,
              subsections: json.subsections.map(
                (subsection: { title: string; text: string }) => ({
                  id: `sub-${Math.random().toString(36)}-${Date.now()}`, // generate random id for subsection
                  title: subsection.title,
                  text: subsection.text,
                })
              ),
            }
          }
          return s
        })
        setSteps({
          ...steps,
          [currentStep]: {
            ...steps[currentStep],
            sections: newSections,
          },
        })
        return newSections
      })
    } catch (error) {
      console.error(error)
    }
    setEnhancing((prev) => ({ ...prev, [section.id]: false }))
  }

  // 1st hidden enhancement of all sections
  useEffect(() => {
    if (!initialSectionsStatus) {
      const enhanceAllSections = async () => {
        if (sections) {
          const enhancePromises = sections.map((section) => {
            return enhance(section)
          })
          setInitialSectionsStatus('enhancing')
          await Promise.all(enhancePromises)
          setInitialSectionsStatus('enhanced')
        }
      }
      enhanceAllSections()
    }
  }, [sections])

  // 2nd hidden enhancement of all sections
  useEffect(() => {
    if (
      initialSectionsStatus === 'enhanced' &&
      mode === GrantApplicationMode.DRAFTING
    ) {
      const enhanceAllSections = async () => {
        if (sections) {
          const enhancePromises = sections.map((section) => {
            return enhance(section)
          })
          setInitialSectionsStatus('enhancingTwice')
          await Promise.all(enhancePromises)
          setInitialSectionsStatus('enhancedTwice')
        }
      }
      enhanceAllSections()
    }
    if (mode == GrantApplicationMode.CONTINUE_EXISTING) {
      setInitialSectionsStatus('enhancedTwice')
    }
  }, [sections])

  const reorder = (list: Section[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    if (removed) {
      result.splice(endIndex, 0, removed)
    }
    return result
  }

  const reorderSubsections = (
    list: Subsection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    if (removed) {
      result.splice(endIndex, 0, removed)
    }
    return result
  }

  const onDragEnd = (result: {
    destination: { index: number } | null
    source: { index: number }
  }) => {
    if (!result.destination || !sections) {
      return
    }

    const reorderedSections = reorder(
      sections,
      result.source.index,
      result.destination.index
    )

    setSections(reorderedSections)
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        sections: reorderedSections,
      },
    })
  }

  const onDragEndSubsections = (result: {
    destination: { index: number } | null
    source: { index: number }
  }) => {
    if (!result.destination || !sections) {
      return
    }

    const reorderedSections = sections.map((section) => {
      if (section.subsections) {
        const reorderedSubsections = reorderSubsections(
          section.subsections,
          result.source.index,
          result.destination?.index ?? 0
        )
        return {
          ...section,
          subsections: reorderedSubsections,
        }
      }
      return section
    })

    setSections(reorderedSections)
  }

  const addSection = () => {
    if (!sections) {
      return
    }
    const id = `${Math.random().toString(36)}-${Date.now()}`
    const newSections = [
      ...sections,
      {
        id,
        title: '',
        text: '',
        subsections: [],
      },
    ]
    setSections(newSections)
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        sections: newSections,
      },
    })
    setEditing({ ...editing, [id]: true })
  }

  const generateDocument = async () => {
    setGeneratingDocument(true)
    const res = await axios(`${API_URL}/google-doc/generate-grant`, {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        sections: sections,
        user: user?.email,
      }),
    })
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        documentUrl: res.data.url,
      },
    })
    setDocumentUrl(res.data.url)
  }

  const finish = async () => {
    setSteps({
      ...steps,
      [currentStep + 1]: {
        numQuestions: 1,
      },
    })
    setCurrentStep(currentStep + 1)
    await getAnswerWrapper(
      `Final document by sections: ${sections
        ?.map(
          (s) =>
            `${s.title}\n${s.text}\n${s.subsections.map((sub) => `${sub.title}\n${sub.text}\n`).join('\n')}`
        )
        .join('\n')}`,
      false
    )
  }

  if (documentUrl) {
    return (
      <div className='m-auto size-full sm:max-w-[100em]'>
        <iframe
          key={iframeKey.toString()}
          src={documentUrl}
          className='m-auto size-full max-w-[100rem]'
        />
        <FloatButton.Group shape='square'>
          <FloatButton
            icon={<RiRefreshLine className='size-5' />}
            className='flex items-center'
            onClick={() => setIframeKey(!iframeKey)}
            tooltip={<div>Refresh editor</div>}
          />
          <FloatButton
            icon={<RiArrowLeftSLine className='size-5' />}
            className='flex items-center'
            onClick={() => {
              setDocumentUrl(undefined)
              setSteps({
                ...steps,
                [currentStep]: {
                  ...steps[currentStep],
                  documentUrl: undefined,
                },
              })
            }}
            tooltip={<div>Back</div>}
          />
          <FloatButton
            type='primary'
            icon={<RiArrowRightSLine className='size-5' />}
            className='flex items-center'
            onClick={finish}
            tooltip={<div>Finish</div>}
          />
        </FloatButton.Group>
      </div>
    )
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={
          !sections || loading || generatingDocument
            ? undefined
            : 'Edit grant application'
        }
      />
      {!sections || loading || initialSectionsStatus !== 'enhancedTwice' ? (
        <div className='m-auto flex flex-col items-center gap-1'>
          <div className='pointer-events-none h-full max-w-[500px]'>
            <Lottie options={{ animationData: lottieScanDocument }} />
          </div>
          <p>Generating grant proposal...</p>
        </div>
      ) : generatingDocument ? (
        <div className='m-auto flex flex-col items-center gap-1'>
          <div className='pointer-events-none h-full max-w-[500px]'>
            <Lottie options={{ animationData: lottieGeneratingDocument }} />
          </div>
          <p>Generating proposal draft...</p>
        </div>
      ) : (
        <div className='m-auto flex w-full flex-col gap-4 p-3 sm:max-w-[100em]'>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='flex flex-col gap-3'
                >
                  {sections?.map((section, index: number) => (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={cn(
                            'flex gap-2 sm:gap-4 rounded-lg bg-surface px-3 py-2 text-left dark:bg-dark-surface text-on-surface dark:text-dark-on-surface',
                            enhancing?.[section.id] &&
                              'opacity-50 pointer-events-none',
                            snapshot.isDragging &&
                              'bg-primary dark:bg-dark-primary text-on-primary dark:text-dark-on-primary'
                          )}
                        >
                          <div {...provided.dragHandleProps}>
                            <RiDraggable className='mt-1 size-5' />
                          </div>
                          <div className='flex w-full flex-col gap-3 text-left'>
                            <div className='flex items-center gap-2'>
                              {editing?.[section.id] ? (
                                <Input
                                  value={textTitle?.[section.id]}
                                  onChange={(event) =>
                                    setTextTitle({
                                      ...textTitle,
                                      [section.id]: event.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className='flex w-full items-center justify-between'>
                                  <h2 className='text-xl font-bold'>
                                    {section.title}
                                  </h2>
                                  <CollapseButton
                                    section={section}
                                    setCollapsed={setCollapsed}
                                    isCollapsed={collapsed?.[section.id]}
                                  />
                                </div>
                              )}
                            </div>

                            {editing?.[section.id] ? (
                              <Input.TextArea
                                autoSize={{ minRows: 5 }}
                                value={section.text}
                                className='w-full'
                                onChange={(event) => {
                                  const newSections = sections?.map((s) => {
                                    if (s.id === section.id) {
                                      return {
                                        ...s,
                                        text: event.target.value,
                                      }
                                    }
                                    return s
                                  })
                                  setSections(newSections)
                                }}
                              />
                            ) : (
                              <>
                                {!collapsed?.[section.id] && (
                                  <div className='markdown-answer break-words text-sm'>
                                    {markdown(section.text)}
                                  </div>
                                )}
                              </>
                            )}
                            <ActionButtons
                              section={section}
                              setSections={setSections}
                              sections={sections}
                              enhancing={enhancing}
                              setEnhancing={setEnhancing}
                              setEditing={setEditing}
                              textTitle={textTitle}
                              setTextTitle={setTextTitle}
                              isEditing={editing?.[section.id]}
                              enhanceSection={enhance}
                            />
                            {!collapsed?.[section.id] && (
                              <DragDropContext onDragEnd={onDragEndSubsections}>
                                <Droppable
                                  droppableId={`${section.id}-subsectionsDroppable`}
                                >
                                  {(provided) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      className='flex flex-col gap-2'
                                    >
                                      {section.subsections?.map(
                                        (subsection, index: number) => (
                                          <Draggable
                                            key={subsection.id}
                                            draggableId={subsection.id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                className={cn(
                                                  'flex gap-2 rounded-lg bg-surface px-3 py-2 text-left dark:bg-dark-surface text-on-surface dark:text-dark-on-surface',
                                                  enhancing?.[subsection.id] &&
                                                    'opacity-50 pointer-events-none',
                                                  snapshot.isDragging &&
                                                    'bg-primary dark:bg-dark-primary text-on-primary dark:text-dark-on-primary'
                                                )}
                                              >
                                                <div
                                                  {...provided.dragHandleProps}
                                                >
                                                  <RiDraggable className='mt-1 h-5 w-fit' />
                                                </div>
                                                <div className='flex w-full flex-col gap-2'>
                                                  <div className='flex items-center gap-2'>
                                                    {editing?.[
                                                      subsection.id
                                                    ] ? (
                                                      <Input
                                                        value={
                                                          textTitle?.[
                                                            subsection.id
                                                          ]
                                                        }
                                                        onChange={(event) =>
                                                          setTextTitle({
                                                            ...textTitle,
                                                            [subsection.id]:
                                                              event.target
                                                                .value,
                                                          })
                                                        }
                                                      />
                                                    ) : (
                                                      <div className='flex w-full items-center justify-between'>
                                                        <h2 className='text-lg font-semibold'>
                                                          {subsection.title}
                                                        </h2>
                                                        <CollapseButton
                                                          section={subsection}
                                                          setCollapsed={
                                                            setCollapsed
                                                          }
                                                          isCollapsed={
                                                            collapsed?.[
                                                              subsection.id
                                                            ]
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                  </div>
                                                  {editing?.[subsection.id] ? (
                                                    <Input.TextArea
                                                      autoSize={{ minRows: 5 }}
                                                      value={subsection.text}
                                                      className='w-full'
                                                      onChange={(event) => {
                                                        const newSections =
                                                          sections?.map((s) => {
                                                            const newSubsections =
                                                              s.subsections.map(
                                                                (sub) => {
                                                                  if (
                                                                    sub.id ===
                                                                    subsection.id
                                                                  ) {
                                                                    return {
                                                                      ...sub,
                                                                      text: event
                                                                        .target
                                                                        .value,
                                                                    }
                                                                  }
                                                                  return sub
                                                                }
                                                              )
                                                            return {
                                                              ...s,
                                                              subsections:
                                                                newSubsections,
                                                            }
                                                          })
                                                        setSections(newSections)
                                                      }}
                                                    />
                                                  ) : (
                                                    <>
                                                      {!collapsed?.[
                                                        subsection.id
                                                      ] && (
                                                        <div className='markdown-answer break-words text-sm'>
                                                          {markdown(
                                                            subsection.text
                                                          )}
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                  <ActionButtons
                                                    section={subsection}
                                                    setSections={setSections}
                                                    sections={sections}
                                                    enhancing={enhancing}
                                                    setEnhancing={setEnhancing}
                                                    setEditing={setEditing}
                                                    textTitle={textTitle}
                                                    setTextTitle={setTextTitle}
                                                    isEditing={
                                                      editing?.[subsection.id]
                                                    }
                                                    isSubsection
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className='flex justify-between gap-2'>
            <Button
              className='w-fit self-end'
              icon={<RiAddLine className='size-5' />}
              onClick={addSection}
            >
              Add Section
            </Button>
            <div className='flex items-center gap-2'>
              <Button onClick={goBack}>Back</Button>
              <Button
                className='w-fit self-end'
                icon={<RiSparklingFill className='size-5' />}
                type='primary'
                onClick={generateDocument}
              >
                <span className='!hidden sm:!block'>
                  Generate Grant Application
                </span>
                <span className='sm:!hidden'>Generate</span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GrantEditor
