'use client'

import { Button, Form, Input } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import StepHeader from '../StepHeader'

interface SpecificProjectStepProps {
  setProjectDetails: (projectDetails?: { [x: string]: string }) => void
  goBack: () => void
}

const SpecificProjectStep: React.FC<SpecificProjectStepProps> = ({
  setProjectDetails,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { currentStep, setCurrentStep, steps, setSteps } =
    useGrantApplicationState()

  const finish = async (values: { [x: string]: string }) => {
    setProjectDetails(values)
    setSteps({
      ...steps,
      [currentStep]: values,
    })
    setCurrentStep(currentStep + 1)
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title='Project Information'
        description='Do you have a specific project in mind for your proposal?'
      />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col'
        layout='vertical'
        requiredMark='optional'
        initialValues={steps[currentStep]}
      >
        <Form.Item
          label='Project Budget'
          name='budget'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea placeholder='e.g., $1,000,000 for building a spaceship prototype' />
        </Form.Item>
        <Form.Item
          label='Project Timeline'
          name='timeline'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea placeholder='e.g., Start: Jan 2024, End: Dec 2024' />
        </Form.Item>
        <Form.Item
          label='Previous Grants'
          name='previousGrants'
          rules={[{ type: 'string' }]}
        >
          <Input.TextArea placeholder='e.g., Received $500,000 from NASA in 2022' />
        </Form.Item>

        <Form.Item
          label='Additional Information'
          name='additionalInformation'
          rules={[{ type: 'string' }]}
          className='w-full'
        >
          <Input.TextArea
            placeholder='e.g., We plan to collaborate with SpaceX for rocket components'
            rows={5}
          />
        </Form.Item>

        <div className='m-0 flex justify-between gap-2'>
          <Button onClick={goBack}>Back</Button>
          <Form.Item className='m-0'>
            <Button htmlType='submit' type='primary'>
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default SpecificProjectStep
