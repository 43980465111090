'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Popover, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { SetStateAction, useRef, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { dateFormat, datetimeFormat } from '@/branding-config'
import { getAnswerDraft } from '@/service/Chatbot'

import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'

const ENHANCEMENT_CHAR_LIMIT = 20000

interface EnhanceButtonProps {
  section: Section | Subsection
  isSubsection?: boolean
  enhancing?: { [key: string]: boolean }
  setEnhancing: (
    value: SetStateAction<
      | {
          [key: string]: boolean
        }
      | undefined
    >
  ) => void
  sections?: Section[]
  setSections: (value: SetStateAction<Section[] | undefined>) => void
  className?: string
  enhanceSection?: (
    section: Section,
    enhanceInstructions?: string
  ) => Promise<void>
}

const EnhanceButton: React.FC<EnhanceButtonProps> = ({
  section,
  isSubsection,
  enhancing,
  setEnhancing,
  sections,
  setSections,
  className,
  enhanceSection,
}) => {
  const { currentStep, steps, questions, setSteps } = useGrantApplicationState()
  const { user } = useAuth()
  const { selectedAgent } = useAgents()
  const { selectedConversation } = useDrawerState()
  const [open, setOpen] = useState(false)
  const [enhanceInstructions, setEnhanceInstructions] = useState('')
  const textareaRef = useRef<any>(null)

  const draftAnswer = (question: string, questions: IQuestion[]) => {
    return getAnswerDraft(
      true,
      question,
      1,
      questions,
      selectedAgent.id,
      undefined,
      true,
      false,
      user?.email,
      selectedConversation
    )
  }

  const createTmpMessage = (question: string): IQuestion => {
    return {
      question: question,
      messages: [
        {
          role: 'user',
          type: 'text',
          domain: true,
          message: question,
          agent: selectedAgent.id,
          drafts: [],
          documents: [],
          titles: [],
          googleDriveUrls: [],
          timestamp: dayjs().format(datetimeFormat),
          isCommand: false,
          feedbackScore: 0,
        },
      ],
    }
  }

  const enhanceSubsection = async () => {
    setOpen(false)
    setEnhancing((prev) => ({ ...prev, [section.id]: true }))
    const question = `
      CONTEXT:
      
      ${sections?.map((s) => JSON.stringify(s)).join('\n')}


      TASK:
      Your goal is to enhance the following grant subsection by expanding on its content, making it significantly more detailed and thorough. The resulting text should be more elaborate and descriptive. Return only the generated text in markdown format:

      ${JSON.stringify(section)}


      ENHANCEMENT INSTRUCTIONS:

      - Ensure that the enhanced text is **at least twice as long** as the original. 2 times more words than the original text.
      - Expand on every idea, providing additional context, explanation, and examples where relevant.
      - Use the same tone and style as the original text. Use the same structure and formatting. Try to mimic the original author's style.
      - Follow these specific guidelines for enhancement: ${enhanceInstructions}
      - Add more very detailed and specific information to the section.
      - Add more numbers and specific timelines and budget numbers.
      - If enhancing titles, ensure they are clear, concise, and descriptive. Do not add "enhanced" or "improved" to the title.
      - Always return subsection text in valid markdown format. Instead of \\n use an actual new line.
      - When listing items subsection text use numbered or bulleted lists in markdown format where appropriate.


      RESPONSE FORMAT:
      Return the enhanced text in valid JSON with the following structure:
      {
        "title": "Subsection Title",
        "text": "Expanded subsection text in markdown",
      }


      ADDITIONAL RULES:
      - Do not use parentheses!
      - Do not use backslashes!
      - FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
      - FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
      `
    const tmpQuestion: IQuestion = createTmpMessage(question)
    const res: { answer: string } = await draftAnswer(question, [
      ...(questions ?? []),
      tmpQuestion,
    ])
    try {
      const json = JSON.parse(
        res.answer.replaceAll('```json', '').replaceAll('```', '')
      )
      setSections((prevSections) => {
        const newSections = prevSections?.map((s) => {
          const newSubsections = s.subsections.map((sub) => {
            if (sub.id === section.id) {
              return {
                id: sub.id,
                title: json.title,
                text: json.text,
              }
            }
            return sub
          })
          return {
            ...s,
            subsections: newSubsections,
          }
        })
        setSteps({
          ...steps,
          [currentStep]: {
            ...steps[currentStep],
            sections: newSections,
          },
        })
        return newSections
      })
    } catch (error) {
      console.error(error)
    }
    setEnhancing((prev) => ({ ...prev, [section.id]: false }))
    setEnhanceInstructions('')
  }

  return (
    <div className='flex gap-2'>
      <Popover
        trigger='click'
        overlayClassName='w-full max-w-md'
        open={open}
        onOpenChange={setOpen}
        content={
          <Form
            autoComplete='off'
            className='flex w-full flex-col items-end'
            layout='vertical'
          >
            <Form.Item
              name='additionalInfo'
              rules={[{ type: 'string' }]}
              className='w-full'
            >
              <Input.TextArea
                ref={textareaRef}
                value={enhanceInstructions}
                size='small'
                className='max-w-64 origin-left overflow-hidden transition-all'
                placeholder='Optional: provide specific instructions to enhance this section'
                onChange={(e) => setEnhanceInstructions(e.target.value)}
                rows={4}
              />
            </Form.Item>
            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                htmlType='submit'
                type='primary'
                onClick={() => {
                  if (isSubsection) {
                    enhanceSubsection()
                  } else {
                    setOpen(false)
                    enhanceSection?.(section as Section, enhanceInstructions)
                    setEnhanceInstructions('')
                  }
                }}
                icon={<RiSparklingFill className={className} />}
              >
                Enhance
              </Button>
            </Form.Item>
          </Form>
        }
      >
        <Tooltip
          title={
            JSON.stringify(section).length > ENHANCEMENT_CHAR_LIMIT
              ? ''
              : 'Enhance'
          }
        >
          <Button
            className='w-fit'
            icon={<RiSparklingFill className={className} />}
            loading={enhancing?.[section.id]}
            disabled={JSON.stringify(section).length > ENHANCEMENT_CHAR_LIMIT}
            size='small'
            type='text'
          >
            {enhancing?.[section.id] ? 'Enhancing...' : ''}
          </Button>
        </Tooltip>
      </Popover>
    </div>
  )
}

export default EnhanceButton
