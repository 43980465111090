'use client'

import { RiBook2Line, RiSearchLine, RiSparklingFill } from '@remixicon/react'
import { Button, Card, Form, Input, Popover, Progress, Skeleton } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { SetStateAction, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { dateFormat, datetimeFormat } from '@/branding-config'
import { cn } from '@/utils/clsx'

import StepHeader from '../StepHeader'
import chatbotAvatar from '../../../../../public/chatbotAvatar.png'
import lottieGrantsAnimation from '../../../../../public/lottieGrants.json'

import { GrantApplicationMode } from '@/types/grants'

interface SelectGrantStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  progressException: boolean
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  retry: (additionalInfo: string) => void
  goBack: () => void
  setProgressException: (progressException: boolean) => void
  finishedSteps: number
}

const SelectGrantStep: React.FC<SelectGrantStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  progressException,
  retry,
  goBack,
  setProgressException,
  finishedSteps,
}) => {
  const [form] = Form.useForm()
  const { setCurrentStep, currentStep, steps, questions, setSteps, mode } =
    useGrantApplicationState()
  const { selectedAgent } = useAgents()
  const { toggleModal } = useModalsState()
  const [open, setOpen] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [text, setText] = useState<string>()
  const [percent, setPercent] = useState(3)
  const [selectedGrant, setSelectedGrant] = useState<number>()

  useEffect(() => {
    if (!loading) {
      try {
        JSON.parse(
          questions[questions.length - 1]?.messages[1]?.message as string
        )
        setText(questions[questions.length - 1]?.messages[1]?.message)
        setProgressException(false)
        setSteps({
          ...steps,
          [currentStep]: {
            numQuestions: 1,
          },
        })
      } catch (e) {
        setText(undefined)
        setProgressException(true)
      }
    }
  }, [questions[questions.length - 1]?.messages[1]?.message, loading])

  useEffect(() => {
    if (finishedSteps === 5) {
      setPercent(100)
      return
    }

    // Calculate the target percent with a slight overshoot to indicate progression beyond the current step
    const targetPercent = finishedSteps * 20 + (finishedSteps + 1) * 3 // Adjust the overshoot value as needed
    const interval = setInterval(() => {
      setPercent((p) => {
        // Calculate the difference between the target (with overshoot) and the current progress
        const diff = targetPercent - p
        // Adjust the progress, moving slower as it gets closer to the target
        return p + diff / 15 // Adjust the divisor to control the "slowness"
      })
    }, 100)
    return () => clearInterval(interval)
  }, [finishedSteps])

  const next = async () => {
    if (mode === GrantApplicationMode.MATCHING) {
      setCurrentStep(10)
    } else {
      setCurrentStep(currentStep + 1)
    }
    setLoading(true)

    let newContext =
      'Select one grant that most closely aligns with my company.'
    if (text && selectedGrant) {
      const grant = JSON.parse(text).grants[selectedGrant]
      newContext = `User selected grant: ${JSON.stringify(grant)}`
    }
    newContext += `\n Provide the real URL for the selected grant.
      Then list out the criteria for successfully applying to that grant and how my company specifically meets each of
      these criteria being extremely specific including facts and data both about the grant and the company.
      List out the typical grant award size for companies based on available data on the web, and include the deadline for applying. Use citations.
      FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
      FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}`
    await getAnswerWrapper(`${newContext}`, true)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={text && !loading ? 'Select the most relevant grant' : undefined}
        description={
          text && !loading
            ? "Select one of the grants listed below. If you don't select a grant, GrantAI will automatically choose the best fitting grant for you."
            : undefined
        }
      />
      <Form
        form={form}
        onFinish={next}
        autoComplete='off'
        className='flex flex-col gap-6'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          {text && !loading ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              <div className='flex flex-col gap-4'>
                {JSON.parse(text).grants.map((grant: any, index: number) => (
                  <Card
                    key={index}
                    className={cn(
                      'bg-surface dark:bg-dark-surface',
                      mode === GrantApplicationMode.DRAFTING
                        ? 'cursor-pointer'
                        : '',
                      index === selectedGrant
                        ? 'bg-primary/70 dark:bg-dark-primary/70'
                        : ''
                    )}
                    onClick={() => {
                      if (mode === GrantApplicationMode.DRAFTING) {
                        selectedGrant === index
                          ? setSelectedGrant(undefined)
                          : setSelectedGrant(index)
                      }
                    }}
                  >
                    <div className='flex flex-col gap-4'>
                      <div className='text-lg font-bold'>{grant.name}</div>
                      <ul className='list-disc'>
                        <li>
                          <span className='font-bold'>Criteria:</span>{' '}
                          {grant.criteria}
                        </li>
                        <li>
                          <span className='font-bold'>Alignment:</span>{' '}
                          {grant.alignment}
                        </li>
                        <li>
                          <span className='font-bold'>Amount:</span>{' '}
                          {grant.amount}
                        </li>
                        <li>
                          <span className='font-bold'>Deadline:</span>{' '}
                          {grant.deadline}
                        </li>
                        {grant.url && (
                          <li>
                            <span className='font-bold'>More Information:</span>{' '}
                            <Link
                              href={grant.url}
                              target='_blank'
                              rel='noreferrer'
                            >
                              {grant.url}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Card>
                ))}
              </div>
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </div>
          ) : (
            <div className='m-auto flex flex-col items-center gap-1'>
              <div className='pointer-events-none h-full max-w-[500px]'>
                <Lottie options={{ animationData: lottieGrantsAnimation }} />
              </div>
              <Progress
                percent={percent}
                showInfo={false}
                status={progressException ? 'exception' : 'active'}
              />
              {progressException ? (
                <>
                  <p>
                    Error occurred while trying to fetch all available grants.
                  </p>
                  <Button
                    className='mt-2'
                    type='default'
                    onClick={() => retry('')}
                  >
                    Retry
                  </Button>
                </>
              ) : (
                <p>Finding the best grants can take a few moments.</p>
              )}
            </div>
          )}

          {!loading && !progressException ? (
            <>
              <div className='m-0 flex justify-end gap-2'>
                <Button onClick={goBack} disabled={open}>
                  Back
                </Button>
                <Button
                  disabled={open}
                  onClick={() => toggleModal('references')}
                  icon={<RiBook2Line className='size-5' />}
                >
                  <span className='!hidden sm:!block'>References</span>
                </Button>
                <Popover
                  trigger='click'
                  className='cursor-pointer'
                  overlayClassName='w-full max-w-md'
                  onOpenChange={setOpen}
                  content={
                    <Form
                      autoComplete='off'
                      className='flex w-full flex-col items-end'
                      layout='vertical'
                    >
                      <Form.Item
                        name='additionalInfo'
                        rules={[{ type: 'string' }]}
                        className='w-full'
                      >
                        <Input.TextArea
                          rows={5}
                          placeholder='Tell us more about the types of grants you are interested in or any specific instructions to help us find the best matches for you!'
                          onChange={(e) => setAdditionalInfo(e.target.value)}
                          className='w-full'
                        />
                      </Form.Item>
                      <Form.Item className='m-0'>
                        <Button
                          className='flex items-center'
                          htmlType='submit'
                          type='primary'
                          onClick={() => {
                            retry(additionalInfo)
                            setAdditionalInfo('')
                          }}
                          icon={<RiSparklingFill className='size-5' />}
                        >
                          Search again
                        </Button>
                      </Form.Item>
                    </Form>
                  }
                >
                  <Button
                    className='flex items-center'
                    disabled={open}
                    icon={<RiSearchLine className='size-5' />}
                  >
                    <span className='!hidden sm:!block'>Search again</span>
                  </Button>
                </Popover>
                <Form.Item className='m-0'>
                  <Button
                    className='flex items-center'
                    htmlType='submit'
                    type='primary'
                    icon={<RiSparklingFill className='size-5' />}
                    disabled={open && mode !== GrantApplicationMode.MATCHING}
                  >
                    Next
                  </Button>
                </Form.Item>
              </div>
              <ReferencesModal
                documents={
                  questions[questions.length - 1]?.messages[1]?.documents ?? []
                }
              />
            </>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={
            selectedAgent.avatar
              ? `data:image/png;base64, ${selectedAgent.avatar}`
              : chatbotAvatar
          }
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={selectedAgent.avatar ? 16 : undefined}
          height={selectedAgent.avatar ? 16 : undefined}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default SelectGrantStep
