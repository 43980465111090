'use client'

import { Button, Checkbox, Form, Input } from 'antd'
import { useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import StepHeader from '../StepHeader'

const options = [
  "I'm applying as a minority (e.g., racial or ethnic minority)",
  "I'm applying as a veteran",
  "I'm applying as a person with disability",
  "I'm applying as female",
  "I'm applying as non-binary or gender non-conforming",
  "I'm applying as LGBTQ+",
  "I'm applying as an immigrant or refugee",
  "I'm applying as an indigenous person",
  "I'm applying as another underrepresented or disadvantaged group",
  'None of the above',
]

interface ProtectedGroupStepProps {
  setProtectedGroup: (protectedGroup: string) => void
  goBack: () => void
}

const ProtectedGroupStep: React.FC<ProtectedGroupStepProps> = ({
  setProtectedGroup,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { currentStep, setCurrentStep, steps, setSteps } =
    useGrantApplicationState()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const finish = async (values: { [x: string]: string }) => {
    let groups = ''
    const statements = values.identity ?? []
    for (const statement of statements) {
      groups += `${statement}\n`
    }
    groups += values.additionalInfo
      ? `\n\n Additional info: ${values.additionalInfo}\n`
      : ''
    setProtectedGroup(groups)
    setCurrentStep(currentStep + 1)
    setSteps({
      ...steps,
      [currentStep]: values,
    })
  }

  const handleChange = (checkedValues: string[]) => {
    const added = checkedValues.length > selectedOptions.length
    if (added) {
      const addedOption = checkedValues.find(
        (option) => !selectedOptions.includes(option)
      )
      if (addedOption === 'None of the above') {
        setSelectedOptions(['None of the above'])
        form.setFieldsValue({ identity: ['None of the above'] })
      } else {
        const newValues = checkedValues.filter(
          (option) => option !== 'None of the above'
        )
        setSelectedOptions(newValues)
        form.setFieldsValue({ identity: newValues })
      }
    } else {
      const newValues = checkedValues.filter(
        (option) => option !== 'None of the above'
      )
      setSelectedOptions(newValues)
      form.setFieldsValue({ identity: newValues })
    }
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title='Protected group information'
        description='Please indicate if any of the following apply to the company (select all that apply).'
        tooltip="To help us match you with the best grants, we need to gather some information about the company's owners/founders. Many grants are designed to support specific groups, and knowing more about the company's owners will allow us to find more eligible opportunities."
      />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-4'
        requiredMark='optional'
        layout='vertical'
        initialValues={steps[currentStep]}
      >
        <div className='items flex flex-col'>
          <Form.Item name='identity' className='w-full'>
            <Checkbox.Group
              className='flex flex-col gap-1'
              options={options}
              value={selectedOptions}
              onChange={handleChange}
            />
          </Form.Item>
          <Form.Item
            label='Additional information'
            name='additionalInfo'
            rules={[{ type: 'string' }]}
          >
            <Input.TextArea
              rows={3}
              placeholder='Share any additional details about the company. Examples include previous grants applied for with preference for protected groups.'
            />
          </Form.Item>
        </div>

        <div className=''>
          <h4 className='font-semibold'>Privacy and Confidentiality Note:</h4>
          <p>
            Your responses will be used solely to match you with grants you may
            be eligible for. We take your privacy seriously and will not share
            your information with any third parties without your consent.
          </p>
        </div>

        <div className='m-0 flex justify-between gap-2'>
          <Button onClick={goBack}>Back</Button>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default ProtectedGroupStep
