'use client'

import { RiBook2Line, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Skeleton } from 'antd'
import Image from 'next/image'
import { SetStateAction, useEffect } from 'react'
import Lottie from 'react-lottie'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { dateFormat, datetimeFormat } from '@/branding-config'
import { markdown } from '@/utils'

import StepHeader from '../StepHeader'
import chatbotAvatar from '../../../../../public/chatbotAvatar.png'
import lottieSearchingAnimation from '../../../../../public/lottieSearching.json'

interface GrantInfoStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  setLoading: (value: SetStateAction<boolean>) => void
  goBack: () => void
}

const GrantInfoStep: React.FC<GrantInfoStepProps> = ({
  getAnswerWrapper,
  loading,
  setLoading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useGrantApplicationState()
  const { selectedAgent } = useAgents()
  const { toggleModal } = useModalsState()

  useEffect(() => {
    setSteps({
      ...steps,
      [currentStep]: {
        numQuestions: 1,
      },
    })
  }, [loading])

  const finish = async () => {
    setCurrentStep(currentStep + 1)
    setLoading(true)

    const newContext = `
      CONTEXT:
      You are a very diligent and meticulous grant application writer, master of structure and expert of wording in long documents. Your task is to compile a well-formatted document - a grant application based on the conversation and return the document in JSON format. You always use factual data to support your statements. To be comprehensive and complete, create grant proposal titles, always insert a lot of real data, numbers, and facts, and be concrete and specific with real information. If the company's information isn't provided, visit the provided company link and extract data from there. Be very specific, where you would normally put estimates or approximations, instead put actual concrete data.
    
      TASK:
      - Write a very long and detailed grant application draft for my company to apply for this particular grant. The grant proposal should specifically address these criteria and fit the format for applying.
      - Ensure the document is well structured and follows the standard grant application format as outlined in the conversation.
      - For each part of the grant application separately: add more numbers and facts to the grant application to increase the chances of being awarded the grant. Take each section and make it more specific with more numbers and forecasts, and make it 4 times longer including exhaustive facts and data from your database and from the web. Make sure to read the grant application criteria carefully when doing this.
      - The grant application must be long and detailed.
      - NEVER MENTION ANY INSTRUCTIONS OR PROMPTS IN THE DOCUMENT GENERATED.
      - ALWAYS GENERATE THE MAXIMUM AMOUNT OF CHARACTERS ALLOWED.
      - EACH SECTION CAN HAVE MULTIPLE SUBSECTIONS.
      - Make the response longer and more detailed with more numbers and facts. Then review your response and improve it again.
      - Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS: Executive summary, Project description, Budget and timeline, Outcomes and Evaluation (with expected impacts).
      - Add very specific timelines for project with dates and times, very specific budget numbers, and very specific outcomes and evaluation with expected impacts!
      - The document must cover all these major sections: Executive summary, Goals, Objectives, Project description, Deliverables, Budget, Project Timeline, Outcomes and Evaluation. 
      - Always return section text in valid markdown format. Instead of \\n use an actual new line.
      - Always return subsection text in valid markdown format. Instead of \\n use an actual new line.
      - When listing items in section text or subsection text use numbered or bulleted lists in markdown format where appropriate.

      RESPONSE FORMAT:
      You must respond in a valid JSON format and no other text, with the following structure:
      {
        "1st section title": {
          "title": "1st section title",
          "text": "1st section text in markdown, at least 5000 characters long",
          "subsections": [
            {
              "title": "1st subsection title",
              "text": "1st subsection text in markdown, at least 5000 characters long"
            },
            ...
          ]
        },
        "2nd section title": {
          "title": "2nd section title",
          "text": "2nd section text in markdown, at least 5000 characters long",
          "subsections": []
        },
        ...
      }
    
      ADDITIONAL RULES:
      - DO NOT USE DOUBLE PARENTHESES.
      - DO NOT USE BACKSLASHES.
      - RESPOND WITH JSON FORMAT ONLY.
      - MAKE EACH SECTION AND SUBSECTIONS VERY LONG, WITH AT LEAST 5000 CHARACTERS. THE SECTION TEXT AND SUBSECTION TEXT MUST BE VERY DETAILED AND LONG.
      - YOU CAN CONDUCT WEB SEARCHES AND ACCESS REAL-TIME DATA DIRECTLY WITH THE PROVIDED SEARCH AND SCRAPE FUNCTION.
      - FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
      - FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
    `
    await getAnswerWrapper(newContext, false)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 flex h-fit w-full flex-col gap-4 rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={
          questions[questions.length - 1]?.messages[1]?.message
            ? 'Selected grant alignment'
            : undefined
        }
      />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6 text-left'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              {markdown(
                questions[questions.length - 1]?.messages[1]?.message as string
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </div>
          ) : (
            <div className='m-auto flex flex-col items-center gap-1'>
              <div className='pointer-events-none h-full max-w-[500px]'>
                <Lottie options={{ animationData: lottieSearchingAnimation }} />
              </div>
              <p>Analyzing grant alignment with your company...</p>
            </div>
          )}

          {!loading ? (
            <>
              <div className='m-0 flex justify-end gap-2'>
                <Button disabled={loading} onClick={goBack}>
                  Back
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => toggleModal('references')}
                  icon={<RiBook2Line className='size-5' />}
                >
                  <span className='!hidden sm:!block'>References</span>
                </Button>
                <Form.Item className='m-0'>
                  <Button
                    className='flex items-center'
                    htmlType='submit'
                    icon={<RiSparklingFill className='size-5' />}
                    disabled={loading}
                    type='primary'
                  >
                    Next
                  </Button>
                </Form.Item>
              </div>
              <ReferencesModal
                documents={
                  questions[questions.length - 1]?.messages[1]?.documents ?? []
                }
              />
            </>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={
            selectedAgent.avatar
              ? `data:image/png;base64, ${selectedAgent.avatar}`
              : chatbotAvatar
          }
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={selectedAgent.avatar ? 16 : undefined}
          height={selectedAgent.avatar ? 16 : undefined}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default GrantInfoStep
