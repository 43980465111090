'use client'

import { RiBook2Line, RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Skeleton } from 'antd'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import ReferencesModal from '@/components/Chatbot/Output/Answer/ReferencesModal'

import { dateFormat, datetimeFormat } from '@/branding-config'
import { markdown } from '@/utils'

import StepHeader from '../StepHeader'
import chatbotAvatar from '../../../../../public/chatbotAvatar.png'
import lottieSearchingAnimation from '../../../../../public/lottieSearching.json'

interface AgentResponseStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
  companyDetails?: { [x: string]: string }
}

const AgentResponseStep: React.FC<AgentResponseStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
  companyDetails,
}) => {
  const [form] = Form.useForm()
  const { selectedAgent } = useAgents()
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useGrantApplicationState()
  const { toggleModal } = useModalsState()
  const [numQuestions, setNumQuestions] = useState<number>(0)
  const [enhancing, setEnhancing] = useState(false)

  useEffect(() => {
    if (!numQuestions && steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep]?.numQuestions ?? 1)
    }
  }, [steps[currentStep]?.numQuestions])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      const newNumQuestions = (numQuestions ?? 1) + 1
      setNumQuestions(newNumQuestions)
      setSteps({
        ...steps,
        [currentStep]: {
          numQuestions: newNumQuestions,
        },
      })
      form.setFieldValue('additionalInfo', '')
      setEnhancing(false)
    }
  }, [loading])

  const finish = async () => {
    setCurrentStep(currentStep + 1)
  }

  const enhance = async () => {
    setEnhancing(true)
    const companyDetailsContext = `Company URL: ${companyDetails?.companyUrl}
    ${companyDetails?.address ? `Address: ${companyDetails.address}` : ''}
    ${companyDetails?.numberOfEmployees ? `Number of employees: ${companyDetails.numberOfEmployees}` : ''}
    ${companyDetails?.annualRevenue ? `Annual revenue: ${companyDetails.annualRevenue}` : ''}
    ${companyDetails?.additionalInfo ? `Additional company information: ${companyDetails.additionalInfo}` : ''}.`
    await getAnswerWrapper(
      `CONTEXT:
      
      ${questions[questions.length - 1]?.messages[1]?.message}
      ${companyDetailsContext}
    

      INSTRUCTIONS:

      Enhance company information with more data and this additional information: ${form.getFieldValue('additionalInfo')}
      Research my company with the search and scrape function and context and list in detail the information you learned about my company.
      Present me with a very detailed description of my company, including the company's address, number of employees, annual revenue, and any additional information you can find.
      Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
      Include precise and detailed financial information about my company.
      FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
      FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}`,
      true
    )
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 flex h-fit w-full flex-col gap-4 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader
        title={
          questions[questions.length - 1]?.messages[1]?.message
            ? 'Company Summary'
            : undefined
        }
      />
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6'
        layout='vertical'
        requiredMark='optional'
      >
        <div className='flex flex-col gap-6'>
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              {markdown(
                questions[questions.length - 1]?.messages[1]?.message as string
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </div>
          ) : (
            <div className='m-auto flex flex-col items-center gap-1'>
              <div className='pointer-events-none h-full max-w-[500px]'>
                <Lottie options={{ animationData: lottieSearchingAnimation }} />
              </div>
              <p>Gathering company information...</p>
            </div>
          )}

          {!loading || enhancing ? (
            <>
              <Form.Item
                label='Additional information'
                name='additionalInfo'
                rules={[{ type: 'string' }]}
              >
                <Input.TextArea
                  rows={4}
                  disabled={enhancing}
                  placeholder='Add additional here like information about the project, objectives of the company, and the types of grants you are interested in if you already have an idea (i.e. SBIR/STTR)'
                />
              </Form.Item>

              <div className='m-0 mt-4 flex justify-end gap-2'>
                <Button disabled={enhancing} onClick={goBack}>
                  Back
                </Button>
                <Button
                  disabled={enhancing}
                  onClick={() => toggleModal('references')}
                  icon={<RiBook2Line className='size-5' />}
                >
                  <span className='!hidden sm:!block'>References</span>
                </Button>
                <Button
                  className='flex items-center'
                  icon={<RiSparklingFill className='size-5' />}
                  disabled={enhancing}
                  onClick={enhance}
                  loading={enhancing}
                >
                  <span className='!hidden sm:!block'>
                    {enhancing ? 'Enhancing...' : 'Enhance'}
                  </span>
                </Button>
                <Form.Item className='m-0'>
                  <Button
                    className='flex items-center'
                    htmlType='submit'
                    type='primary'
                    disabled={enhancing}
                  >
                    Next
                  </Button>
                </Form.Item>
              </div>
              <ReferencesModal
                documents={
                  questions[questions.length - 1]?.messages[1]?.documents ?? []
                }
              />
            </>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={
            selectedAgent.avatar
              ? `data:image/png;base64, ${selectedAgent.avatar}`
              : chatbotAvatar
          }
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={selectedAgent.avatar ? 16 : undefined}
          height={selectedAgent.avatar ? 16 : undefined}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default AgentResponseStep
