'use client'
import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input, Radio, Space } from 'antd'
import Lottie from 'react-lottie'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import StepHeader from '../StepHeader'
import lottieGrantsAnimation from '../../../../../public/lottieGrants.json'

interface FeedbackStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const FeedbackStep: React.FC<FeedbackStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const [form] = Form.useForm()
  const { steps, setCurrentStep, currentStep, setSteps } =
    useGrantApplicationState()

  const finish = async (values: { [x: string]: string }) => {
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        values,
      },
    })
    setCurrentStep(currentStep + 1)

    const feedback = `
      These are my replies to the 5 questions you asked me:
      1. How satisfied are you with the Grant AI assistance you received?
      ${values.feedback1}

      2. How would you rate the quality of the proposal Grant AI produced?
      ${values.feedback2}

      3. Compared to normal non-assisted grant writing, how would you rate the experience of using Grant AI for writing a grant proposal?
      ${values.feedback3}

      4. Compared to normal non-assisted grant writing, how would you rate the experience of using Grant AI for writing a grant proposal?
      ${values.feedback4 ?? '-'}
      
      5. What other questions should be asked of users of Grant AI during onboarding to providing even better results?
      ${values.feedback5 ?? '-'}      

      Respond just with: Thank you for your feedback. We will take it into account.
      `
    await getAnswerWrapper(feedback, false)
  }

  return (
    <div className='mx-auto my-4 flex h-fit w-full flex-col gap-4 rounded-lg bg-surface p-6 text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <StepHeader title={loading ? undefined : 'Feedback'} />
      {loading ? (
        <div className='m-auto flex flex-col items-center gap-1'>
          <div className='pointer-events-none h-full max-w-[500px]'>
            <Lottie options={{ animationData: lottieGrantsAnimation }} />
          </div>
          <p>Finalizing grant proposal...</p>
        </div>
      ) : (
        <Form
          form={form}
          onFinish={finish}
          autoComplete='off'
          className='flex flex-col gap-6 text-left'
          labelAlign='left'
          initialValues={steps[currentStep]}
        >
          <p>
            Thank you for using GrantAI! To improve your experience, we would
            like to ask a few questions. Your feedback is essential in helping
            us enhance our service.
          </p>

          <div className='flex flex-col gap-2'>
            <div className='pb-2'>
              How satisfied are you with the Grant AI assistance you received?
            </div>
            <div className='items flex flex-col gap-4'>
              <Form.Item
                name='feedback1'
                className='w-full'
                rules={[
                  { required: true, message: 'Please select an option.' },
                ]}
              >
                <Radio.Group>
                  <Space direction='vertical'>
                    <Radio value='Extremely satisfied'>
                      Extremely satisfied
                    </Radio>
                    <Radio value='Very satisfied'>Very satisfied</Radio>
                    <Radio value='Moderately satisfied'>
                      Moderately satisfied
                    </Radio>
                    <Radio value='Slightly satisfied'>Slightly satisfied</Radio>
                    <Radio value='Not at all satisfied'>
                      Not at all satisfied
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          <div className='flex flex-col gap-2'>
            <div className='pb-2'>
              How would you rate the quality of the proposal Grant AI produced?
            </div>
            <Form.Item
              name='feedback2'
              className='w-full max-w-screen-sm'
              rules={[{ required: true, message: 'Please select an option.' }]}
            >
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio value='Excellent'>Excellent</Radio>
                  <Radio value='Very good'>Very good</Radio>
                  <Radio value='Good'>Good</Radio>
                  <Radio value='Fair'>Fair</Radio>
                  <Radio value='Poor'>Poor</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='pb-2'>
              Compared to normal non-assisted grant writing, how would you rate
              the experience of using Grant AI for writing a grant proposal?
            </div>
            <Form.Item
              name='feedback3'
              className='w-full max-w-screen-sm'
              rules={[{ required: true, message: 'Please select an option.' }]}
            >
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio value='Much better'>Much better</Radio>
                  <Radio value='Somewhat better'>Somewhat better</Radio>
                  <Radio value='About the same'>About the same</Radio>
                  <Radio value='Somewhat worse'>Somewhat worse</Radio>
                  <Radio value='Much worse'>Much worse</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='pb-2'>
              What other comments, concerns, or reflections do you have about
              Grant AI? Please be as specific as possible as your feedback is
              extremely important to us.
            </div>
            <Form.Item name='feedback4' className='w-full'>
              <Input.TextArea
                placeholder='Provide your comments, concerns, or reflections about Grant AI'
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='pb-2'>
              What other questions should be asked of users of Grant AI during
              onboarding to providing even better results?
            </div>
            <Form.Item name='feedback5' className='w-full'>
              <Input.TextArea
                placeholder='Provide other questions you might have'
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </div>

          <div className='m-0 flex justify-between gap-2'>
            <Button onClick={goBack}>Back</Button>
            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                htmlType='submit'
                type='primary'
                icon={<RiSparklingFill className='size-5' />}
              >
                Finish
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  )
}

export default FeedbackStep
